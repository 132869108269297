/**
 * 採用情報の詳細ページの ld-json メタタグ
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import isObject from "lodash/isObject"

import markdownStringify from "../markdown-stringify"

// JSON の中身が不定なので GraphQL ではなくここで直接 import しています
import hiringOrganzation from "../../google-jobs/hiring-organization"

const GoogleJobsJson = ({ job }) => {
  // See: https://developers.google.com/search/docs/advanced/structured-data/job-posting?hl=ja
  // 必須プロパティ:
  // - datePosted
  // - description
  // - hiringOrganization
  // - jobLocation
  // - title

  if (!isObject(hiringOrganzation)) {
    throw new TypeError(`"google-jobs/hiring-organization.json" is not an object: ${typeof hiringOrganzation}.`)
  }

  // nullable な要素の値が null の場合は除外する
  const addressEntries = [
    ["@type", "PostalAddress"],
    ["postalCode", job.gj_address_postalcode],
    ["addressRegion", job.gj_address_addressregion],
    ["addressLocality", job.gj_address_addresslocality],
    ["streetAddress", job.gj_address_streetaddress],
    ["addressCountry", `日本`],
  ].filter(([k, v]) => v)

  let data = {
    "@context" : "https://schema.org/",
    "@type" : "JobPosting",
    "datePosted": job.gj_date_posted,
    // `gj_description` は HTML を含む Markdown なので
    // 共通の `Markdown` コンポーネントを通して文字列化する
    "description": markdownStringify(job.gj_description),
    "hiringOrganization": hiringOrganzation,
    "jobLocation": {
      "@type": "Place",
      "address": Object.fromEntries(addressEntries),
    },
    "title": job.gj_title,
    "validThrough": job.gj_valid_through,
  }

  // 推奨プロパティ:
  // - validThrough （ CMS の編集画面では必須にしてあるので上に入れてある）
  // - baseSalary
  // - employmentType

  // 給与情報が正しく記入されていれば追加
  if (job.gj_base_salary_unit_text &&
      job.gj_base_salary_min_value &&
      job.gj_base_salary_max_value) {
    data["baseSalary"] = {
      "@type": "MonetaryAmount",
      "currency": "JPY",
      "value": {
        "@type": "QuantitativeValue",
        // TODO: str から int に変換する
        "minValue": asInt(job.gj_base_salary_min_value),
        "maxValue": asInt(job.gj_base_salary_max_value),
        "unitText": job.gj_base_salary_unit_text,
      },
    }
  }

  // 雇用タイプが記入されていれば追加
  if (job.gj_employment_type) {
    data["employmentType"] = job.gj_employment_type
  }

  // 追加情報があれば追加
  let gj_extra
  try {
    gj_extra = JSON.parse(job.gj_extra)

    // `gj_extra` の値は number や bool や null のこともあるのでチェックする
    // `object` でなければメッセージを出して `{}` にフォールバックする
    if (!isObject(gj_extra)) {
      throw new TypeError(`"gj_extra" is not an object: ${typeof gj_extra}.`)
    }
  } catch (err) {
    gj_extra = {}
    console.warn(err)
    console.warn(`Failed to parse "gj_extra" field value as JSON.`)
  }

  data = {
    ...data,
    ...gj_extra,
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(data)}</script>
    </Helmet>
  )
}

GoogleJobsJson.propTypes = {
  job: PropTypes.shape({
    gj_date_posted: PropTypes.string,
    gj_description: PropTypes.string,
    gj_address_postalcode: PropTypes.string,
    gj_address_addressregion: PropTypes.string,
    gj_address_addresslocality: PropTypes.string,
    gj_address_streetaddress: PropTypes.string,
    gj_title: PropTypes.string,
    gj_valid_through: PropTypes.string,
    gj_base_salary_unit_text: PropTypes.string,
    gj_base_salary_min_value: PropTypes.string,
    gj_base_salary_max_value: PropTypes.string,
    gj_employment_type: PropTypes.string,
    gj_extra: PropTypes.string,
  }),
}

// `parseInt()` に失敗したときは `NaN` が返されるので `value` にフォールバックする
const asInt = (value) => parseInt(value, 10) || value

// フラグメントもあわせて提供する
export const query = graphql`
  fragment TbJobGoogleJobsJson on TbJob {
    # date
    gj_date_posted(formatString: "YYYY-MM-DD")
    gj_description
    gj_address_postalcode
    gj_address_addressregion
    gj_address_addresslocality
    gj_address_streetaddress
    gj_title
    # date
    gj_valid_through(formatString: "YYYY-MM-DD")
    gj_base_salary_unit_text
    gj_base_salary_min_value
    gj_base_salary_max_value
    gj_employment_type
    # JSON
    gj_extra
  }
`

export default GoogleJobsJson

/**
 * ハイライトのための下線のついたページタイトルの h1
 */

import * as React from "react"
import PropTypes from "prop-types"

const BorderedPageTitle = ({ children }) => {
  return (
    <h1 className="border-b-2 border-headingBorder pc:px-4 pc:pb-4 pc:mb-6 pc:text-2xl tablet_sp:text-base tablet_sp:pb-2 tablet_sp:mb-3">{children}</h1>
  )
}

BorderedPageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
}

export default BorderedPageTitle

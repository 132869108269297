/**
 * Markdown を HTML 化してさらに string に変換する
 *
 * @param text string
 *
 * @return string
 */

import * as React from "react"
import ReactDOMServer from "react-dom/server"

import Markdown from "./markdown"

const markdownStringify = (text) => {
  return ReactDOMServer.renderToStaticMarkup(
    <Markdown>{text || ``}</Markdown>
  )
}

export default markdownStringify

import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import GoogleJobsJson from "../components/tbjob/google-jobs-json"
import Layout from "../components/layout"
import Markdown from "../components/markdown"
import BorderedPageTitle from "../components/bordered-page-title"

const Template = ({ data }) => {
  const { node, careerPage } = data
  const title = node.title

  const breadcrumbs = [
    ...((careerPage && careerPage?.path) ? [
      { label: careerPage.title, href: careerPage.path },
    ] : []),
    { label: title, href: null },
  ]

  // 第 3 引数は `html`|`text`
  const rows = [
    new Row('職種', node.job_type, `text`),
    new Row('雇用形態', node.employment_type, `text`),
    new Row('勤務地', node.location, `text`),
    new Row('資格', node.qualifications, `html`),
    new Row('給与', node.base_salary, `html`),
    new Row('待遇・福利厚生', node.benefits, `html`),
    new Row('勤務時間', node.working_hours, `text`),
    new Row('採用予定人数', node.number, `text`),
    new Row('応募方法', node.application, `html`),
    new Row('お問い合わせ先', node.contact, `html`),
    new Row('備考・その他', node.misc, `html`),
  ]

  return (
    <Layout
      title={title}
      description={node.meta_description}
      keywords={node.meta_keywords}
      image={node.ogp_image?.publicURL}
      breadcrumbs={breadcrumbs}
    >
      <GoogleJobsJson job={node} />
      <div className="pc:flex pc:flex-row">
        <div className="pc:w-1/2 pc:pr-4 tablet_sp:mb-6">
          <BorderedPageTitle>{title}</BorderedPageTitle>
          <div className="pc:px-4 pc:mb-6 pc:text-lg tablet_sp:mb-3 tablet_sp:text-sm">
            <Markdown>{node.description || ``}</Markdown>
          </div>
          <h3 className="pc:px-4 tablet_sp:text-sm">募集要項</h3>
          <DetailTable rows={rows} />
        </div>
        <div className="pc:w-1/2 pc:pl-4">
          <Markdown>{node.body ?? ``}</Markdown>
        </div>
      </div>
    </Layout>
  )
}

class Row {
  constructor(label, value, type) {
    this.label = label
    this.value = value
    this.type = type
  }
}

/**
 * 採用情報詳細のテーブル
 */
const DetailTable = ({ rows }) => {
  return (
    <table className="w-full border-t-4 border-b-4 border-lineJobsDetail">
      <tbody>
        {rows
          .filter(IsValueNonEmpty)
          .map((r) => (
            <TableRow key={r.label} label={r.label} value={r.value} type={r.type} />
          ))
        }
      </tbody>
    </table>
  )
}

const TableRowProps = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.oneOf([`html`, `text`]),
}

DetailTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.exact(TableRowProps).isRequired),
}

const TableRow = ({ label, value: rawValue, type }) => {
  const value = rawValue || ``
  return (
    <tr key={label} className="border-b-2 border-lineJobsDetail">
      <ThLeft>{label}</ThLeft>
      <TdRight>
        {(type === `html`) ? (<Markdown>{value ?? ``}</Markdown>) : value}
      </TdRight>
    </tr>
  )
}

TableRow.propTypes = TableRowProps

const ThLeft = ({ children }) => {
  return (
    <th className="w-1/4 py-5 border-none text-left break-words bold tablet_sp:pl-0 tablet_sp:text-sm">{children}</th>
  )
}

const TdRight = ({ children }) => {
  return (
    <td className="py-5 border-none text-left break-all tablet_sp:pr-0 tablet_sp:text-xs">{children}</td>
  )
}

const IsValueNonEmpty = ({ value }) => {
  if (typeof value === `string`) {
    return value.trim()
  }

  return value
}

export default Template

export const pageQuery = graphql`
  query($path: String!) {
    careerPage: tbPage(path: {eq: "/career/"}) {
      title
      path
    }
    node: tbJob(path: { eq: $path }) {
      title
      ogp_image {
        publicURL
      }
      meta_description
      meta_keywords
      file {
        publicURL
      }
      date_posted
      employment_type
      job_type
      base_salary
      working_hours
      location
      benefits
      description
      qualifications
      number
      application
      misc
      contact
      body
      ...TbJobGoogleJobsJson
    }
  }
`
